/**
 * Get current APP_MODE (auth | guest)
 */
export const useAppMode = () => useRuntimeConfig().public.appMode;

/**
 * Check if current APP_MODE is auth. i.e. requires Login and Authentication workflow.
 */
export const isAppInAuthMode = () =>
  useRuntimeConfig().public.appMode === "auth";

/**
 * Check if current APP_MODE is guest. i.e. NO Login required and, any guest user can just the app.
 */
export const isAppInGuestMode = () =>
  useRuntimeConfig().public.appMode === "guest";
